import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PagePrestamosPersonales = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Sacá tu préstamo' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Préstamos personales de hasta $900.000: ¡Obtén el impulso financiero que necesitas en minutos!💸'
          subheader='Solicita en línea y elige entre diversas opciones para conseguir el préstamo perfecto para ti.'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://finguru.com.ar/solicitar/buscar-ofertas">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Investiga y compara: Antes de solicitar un préstamo personal en línea, tómate el tiempo para investigar y comparar diferentes opciones. Examina las tasas de interés, los plazos de pago, los requisitos y las opiniones de otros clientes sobre las entidades financieras disponibles. Esto te permitirá tomar una decisión informada y encontrar la mejor opción para tus necesidades.
          <br><br>
          Selecciona el monto y plazo: Determina el monto exacto que necesitas y establece un plazo de pago que se ajuste a tus posibilidades. Recuerda que es importante elegir una cuota mensual que puedas manejar sin dificultad para evitar problemas financieros en el futuro.
          <br><br>
          Completa la solicitud en línea: Una vez que hayas seleccionado la entidad financiera de tu preferencia, accede a su página web oficial y busca la sección de solicitud de préstamos personales. Allí encontrarás un formulario en línea que deberás completar con tu información personal, datos de contacto, situación laboral, ingresos y otros detalles relevantes. Asegúrate de proporcionar información precisa y veraz.

          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          Adjunta la documentación requerida: En algunos casos, las entidades financieras en línea solicitarán documentación adicional para verificar tu identidad y solvencia económica. Estos documentos pueden incluir una copia de tu documento de identidad, comprobantes de ingresos, extractos bancarios y recibos de servicios públicos. Escanea o fotografía estos documentos y adjúntalos de manera segura a través de la plataforma indicada.
          <br><br>
          Espera la aprobación: Una vez que hayas completado la solicitud y enviado la documentación, el siguiente paso es esperar la aprobación del préstamo. La entidad financiera evaluará tu solicitud y verificará la información proporcionada. Si todo está en orden, recibirás una notificación de aprobación junto con los detalles del préstamo, como la tasa de interés y el plazo de pago.
          <br><br>
          Firma electrónica: Una vez que hayas recibido la aprobación del préstamo, deberás proceder a firmar electrónicamente el contrato. Lee detenidamente los términos y condiciones del préstamo y asegúrate de entenderlos completamente antes de proceder. La firma electrónica es un proceso seguro y legalmente válido que te permite formalizar el acuerdo sin necesidad de papeleo físico.
          
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://finguru.com.ar/solicitar/buscar-ofertas">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Recibe el dinero en tu cuenta bancaria: Una vez que hayas firmado electrónicamente el contrato, el dinero del préstamo se depositará directamente en tu cuenta bancaria. Este proceso suele ser rápido y puede tomar tan solo unas pocas horas o hasta un par de días, dependiendo de la entidad financiera y el sistema de transferencia utilizado.
          <br><br>
          Recuerda que solicitar préstamos personales en línea ofrece comodidad y agilidad, pero también implica responsabilidad financiera. Antes de tomar un préstamo, evalúa tus capacidades de pago y asegúrate de comprender todos los términos y condiciones del préstamo. Utiliza esta oportunidad para cumplir tus metas y proyectos financieros, pero siempre con prudencia y planificación adecuada.
          
          
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://finguru.com.ar/solicitar/buscar-ofertas">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PagePrestamosPersonales
